import { React } from "react";

export default function PaymentFailedPopUp({ seePaymentFailed, language }) {
    return (
        <div className="pay-popup-wrap" id="phonepe-pop" style={{ display: seePaymentFailed ? "block" : "none" }}>
            <div className="paypopup-box">
                <div className="paypop-content">
                    <div className="logo-image">
                        <img src="images/logo.png" alt="Logo Image" />
                    </div>
                    <div className="wrong-image">
                        <img src="images/wrong.png" alt="Wrong Image" />
                    </div>
                    <div className="subcribe-text">
                        {/* <p className="textTitle">Payment Failed</p>
                        <p>Oops !! your payment failed</p>
                        <p>Enjoy ad free movies, TV Shows, Music Videos.</p> */}
                        <p className="textTitle">{language?.pay_popup_payment_fail_header1}</p>
                        <p>{language?.pay_popup_payment_fail_header2}</p>
                        <p>{language?.pay_popup_payment_fail_header3}</p>
                    </div>
                    <div className="cpbtnsb">
                        <a href={process.env.REACT_APP_HUNGAMA_WEB_URL} className="goldbtn">
                            {/* Retry */}
                            {language?.pay_popup_payment_fail_button_text}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}