import React, { useState, useEffect } from "react";
import { confirmOtp, subscribePlan } from "../../../utils/services";

export default function OtpPopUp({ seeOtp, setSeeOtp, selectedPlan, subcribeRequest, subcribeResponse, setSubcribeResponse, setSeeCongratulation, plansData, planDetailsId, lang }) {
    const [otp, setOtp] = useState(["", "", "", "", "", ""]);
    const [error, setError] = useState("");
    const [resendAvailable, setResendAvailable] = useState(false);
    const [timer, setTimer] = useState(900);
    const [intervalId, setIntervalId] = useState(null);

    useEffect(() => {
        if (seeOtp) {
            setResendAvailable(false);
            setTimer(900);
            startCountdown();
        }

        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [seeOtp]);

    const startCountdown = () => {
        const newIntervalId = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer > 0) {
                    return prevTimer - 1;
                } else {
                    setResendAvailable(true);
                    clearInterval(newIntervalId);
                    return 0;
                }
            });
        }, 1000);
        setIntervalId(newIntervalId);
    };

    const handleOtpChange = (e, index) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            if (value && index < otp.length - 1) {
                document.getElementById(`otp-input-${index + 1}`).focus();
            }
        }
        setError("");
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace" && !otp[index] && index > 0) {
            document.getElementById(`otp-input-${index - 1}`).focus();
        }
        setError("");
    };

    const handleResendOtp = () => {
        setError("");
        setResendAvailable(false);
        setTimer(900);
        if (intervalId) clearInterval(intervalId);
        startCountdown();
        handleConfirm();
    };

    const handleConfirm = async () => {
        const response = await subscribePlan({
            product_id: subcribeRequest?.product_id,
            platform_id: subcribeRequest?.platform_id,
            country: subcribeRequest?.country,
            identity: subcribeRequest?.identity,
            payment_id: 15,
            plan_details_id: planDetailsId || selectedPlan?.plan_details_id,
            lang: lang,
            ...(planDetailsId ? { upgrade_plan_details_id: selectedPlan?.plan_details_id } : {})
        })
        if (response.status === "success") {
            setSubcribeResponse(response.data.info)
        } else {
            setError(response.data.error_details);
        }
    }

    const handleVerifyOtp = async () => {
        const response = await confirmOtp({
            product_id: subcribeRequest?.product_id,
            platform_id: subcribeRequest?.platform_id,
            country: subcribeRequest?.country,
            identity: subcribeRequest?.identity,
            payment_id: 15,
            otp: Number(otp.join("")),
            id: subcribeResponse?.id,
            token: subcribeResponse?.token,
            plan_details_id: planDetailsId || selectedPlan?.plan_details_id,
            lang: lang,
            ...(planDetailsId ? { upgrade_plan_details_id: selectedPlan?.plan_details_id } : {})
        });
        if (response.status === "success") {
            setSeeCongratulation(true);
            setSeeOtp(false);
        } else {
            setError(response.data.error_details);
        }
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
    };

    const handlePaste = (e) => {
        const paste = e.clipboardData.getData("text");
        if (/^\d+$/.test(paste) && paste.length === otp.length) {
            const newOtp = paste.split("");
            setOtp(newOtp);
            newOtp.forEach((digit, index) => {
                document.getElementById(`otp-input-${index}`).value = digit;
            });
        }
        e.preventDefault();
    };

    return (
        <div className="pay-popup-wrap" id="phonepe-pop" style={{ display: seeOtp ? "block" : "none" }}>
            <div className="paypopup-box">
                <div className="paypop-content">
                    <div className="logo-image">
                        <img src="images/logo.png" alt="Logo Image" />
                    </div>
                    <div className="subcribe-text">
                        {/* <p>To confirm your subscription, please enter the 6-digit OTP sent to your registered mobile number</p> */}
                        <p>{plansData?.language?.pay_popup_confirm_otp_header1}</p>
                        <p>{plansData?.language?.pay_popup_confirm_otp_header2} +855 {subcribeResponse?.msisdn}</p>
                    </div>
                    <div className="otp-input-container">
                        {otp.map((digit, index) => (
                            <input
                                key={index}
                                id={`otp-input-${index}`}
                                type="text"
                                maxLength="1"
                                value={digit}
                                onChange={(e) => handleOtpChange(e, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                onPaste={handlePaste}
                                className={`otp-input-box ${error && "otp-error-box"}`}
                            />
                        ))}
                    </div>
                    {error && <p className="error-text">{error}</p>}
                    <div className="cpbtnsb">
                        <a
                            onClick={() => handleVerifyOtp()}
                            className="goldbtn"
                        >
                            {/* Verify */}
                            {plansData?.language?.pay_popup_verify_button}
                        </a>
                    </div>
                    {/* <p className="pop-cancel" onClick={() => setSeeOtp(false)}>Cancel</p> */}
                    <p className="pop-cancel" onClick={() => setSeeOtp(false)}>{plansData?.language?.pay_popup_cancel}</p>
                    <div className="resend-otp-container">
                        {/* Didn't receive OTP? {resendAvailable ? ( */}
                        {plansData?.language?.pay_popup_confirm_otp_txt1} {resendAvailable ? (
                            <span className="resend-otp" onClick={() => handleResendOtp()}>
                                {/* Resend OTP */}
                                {plansData?.language?.pay_popup_resend_otp_button}
                            </span>
                        ) : (
                            <span className="resend-otp">
                                {/* Resend OTP in {formatTime(timer)} */}
                                {plansData?.language?.pay_popup_resend_otp_button} in {formatTime(timer)}
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}