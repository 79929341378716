import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import ReactGA from "react-ga";
import { checkOrder } from "../../utils/payments";

export function Retry(props) {
  //  GA code
  const TRACKING_ID = `${process.env.REACT_APP_GA_CODE}`; // OUR_TRACKING_ID
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview(window.location.pathname);
  }, [TRACKING_ID]);

  const orderid = props.order_id;
  const lang = props.lang;
  let history = useHistory();
  useEffect(() => {
    checkOrder(orderid).then((res) => {
      if (res.data) {
        const {
          identity,
          plan_id,
          product_id,
          country,
          platform_id,
          plan_type,
          aff_code,
          content_id,
          order_status,
          msisdn,
        } = res.data.order_data;
        let url;
        /* get sessionStorage for auth and upilist for android */
        let userInfo = { upilist: "" };
        if (platform_id === 1) {
          let sessionData = JSON.parse(sessionStorage.getItem("userRetryInfo"));
          if (Number(identity) === Number(sessionData?.identity)) {
            // userInfo.auth = sessionData?.auth ? sessionData?.auth : "";
            userInfo.upilist = sessionData?.upilist ? sessionData?.upilist : "";
          }
        }

        // if (order_status === 5) {
        //     url = `/payment?auth=&retry=1&plan_id=${plan_id}&identity=${identity}&product_id=${product_id}&country=${country}&platform_id=${platform_id}&plan_type=${plan_type}&aff_code=${aff_code}&content_id=${content_id}&extra_data=`;
        // } else {
        //     url = `http://hungama.com/payment/failed`;
        // }
        if (order_status === 5 && product_id === 1 && platform_id !== 8) {
          url = `/payment?auth=&retry=1&plan_id=${plan_id}&identity=${identity}&product_id=${product_id}&country=${country}&platform_id=${platform_id}&plan_type=${plan_type}&aff_code=${aff_code}&content_id=${content_id}&lang=${lang}&extra_data=&upilist=${userInfo?.upilist}`;
        } else if (order_status === 5 && product_id === 3) {
          url = `/pleng/payment?auth=&retry=1&plan_id=${plan_id}&identity=${identity}&product_id=${product_id}&country=KH&platform_id=${platform_id}&plan_type=${plan_type}&aff_code=${aff_code}&content_id=${content_id}&lang=${lang}&extra_data=`;
        } else if (
          order_status === 5 &&
          product_id === 1 &&
          platform_id === 8
        ) {
          url = `/nonapp_payment?retry=1&plan_id=${plan_id}&plan_type=${plan_type}&product_id=${product_id}&platform_id=${platform_id}&country=${country}&lang=${lang}&non_app_source=${msisdn}`;
        } else {
          url = `http://hungama.com/payment/failed`;
        }
        window.location.href = url;
      } else {
        history.push("/404");
      }
    });
  }, [history, orderid, lang]);
  return <></>;
}
