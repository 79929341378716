import React, { useState } from "react";
import { subscribePlan } from "../../../utils/services";
import { langConvrt } from "../../../utils";

export default function SubscribePopUp({ seeSubcribe, setSeeSubcribe, selectedPlan, subcribeRequest, setSeeOtp, setSubcribeResponse, plansData, planDetailsId, lang }) {
    const [error, setError] = useState("");

    const handleConfirm = async () => {
        const response = await subscribePlan({
            product_id: subcribeRequest?.product_id,
            platform_id: subcribeRequest?.platform_id,
            country: subcribeRequest?.country,
            identity: subcribeRequest?.identity,
            payment_id: 15,
            plan_details_id: planDetailsId || selectedPlan?.plan_details_id,
            lang: lang,
            ...(planDetailsId ? { upgrade_plan_details_id: selectedPlan?.plan_details_id } : {})
        })
        if (response.status === "success") {
            setSeeOtp(true)
            setSeeSubcribe(false)
            setSubcribeResponse(response.data.info)
        } else {
            setError(response.data.error_message);
        }
    }
    return (
        <div className="pay-popup-wrap" id="phonepe-pop" style={{ display: seeSubcribe ? "block" : "none" }}>
            <div className="paypopup-box">
                <div className="paypop-content">
                    <div className="logo-image">
                        <img src="images/logo.png" alt="Logo Image" />
                    </div>
                    <div className="subcribe-text">
                        <p>{plansData?.language?.pay_popup_confimation_txt1} {langConvrt(selectedPlan?.plan_name, plansData?.defaultlang, plansData?.language)} {plansData?.language?.pay_popup_confimation_txt2} {selectedPlan?.plan_currency_symbol}{selectedPlan?.plan_price}/{langConvrt(selectedPlan?.duration?.text, plansData?.defaultlang, plansData?.language)} {plansData?.language?.pay_popup_confimation_txt3}</p>
                    </div>
                    {error && <p className="error-text">{error}</p>}
                    <div className="cpbtnsb">
                        <a
                            onClick={() => handleConfirm()}
                            className="goldbtn"
                        >
                            {/* Confirm */}
                            {plansData?.language?.pay_confirm_button}
                        </a>
                    </div>
                    {/* <p className="pop-cancel" onClick={() => setSeeSubcribe(false)}>Cancel</p> */}
                    <p className="pop-cancel" onClick={() => setSeeSubcribe(false)}>{plansData?.language?.pay_popup_cancel}</p>
                </div>
            </div>
        </div>
    );
}
