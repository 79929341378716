import React from 'react';
import { useHistory } from 'react-router-dom';

import Header from "../subpages/Header";
import Sidebar from "../subpages/Plans/Sidebar";
import Footer from "../subpages/Footer";

import { plansReducer } from '../../utils/reducers';
import { defaultPlans } from "../../../constants";

import { useImmerReducer } from "use-immer";
import { Image } from "../subcomponents/Elements";

export default function Notfound() {
    const [plansData, setPlansData] = useImmerReducer(plansReducer, defaultPlans);
    let history = useHistory();
    const updateMultipleFormData = (obj) => {
        setPlansData({ type: "updateMultipleFormData", payload: obj })
    }

    return (
        <div id="wrapper">
            <Header plansData={plansData} updateMultipleFormData={updateMultipleFormData} />
            <div id="content">
                <div className="main-contentBox-wrapper">
                    <Sidebar plansData={plansData} />
                    <div className="main-area-container remove-add-container">
                        <div className="inner-mid-area">
                            <div className="pdlr-40">
                                <div className="breadcrumbs-nav flex1">
                                    <div className="brdcrms-nav-left">
                                        <button className="btn-38" onClick={e => history.goBack()}><span className="icon-Back"></span></button>
                                        <ul className="brdcms-ul">
                                            <li><a href="!#">Discover</a></li>
                                            <li><a href="!#">Buy Premium</a></li>
                                            <li><a href="!#">Gold Plan</a></li>
                                            <li><a href="!#">Payment Confirmation</a></li>
                                        </ul>
                                    </div>
                                    <div className="brdcrms-nav-right">

                                    </div>
                                </div>
                            </div>
                            <div className="pdlr-40 mb-20 d-flex">
                                <div className="left-box">
                                    <div className="box-content blur-box">
                                        <div className="box-content-inner">
                                            <div className="text-center w-100">
                                                {/* <Image src="hungama-gold.png" className="hungama-gold" alt="" /> */}
                                                <img
                                                    src="images/logo.png"
                                                    className="hungama-gold"
                                                    alt="Cell Card Logo"
                                                />
                                            </div>
                                            <Image src="failed.svg" className="success-icon" alt="" />
                                            <h3 className="title">404</h3>
                                            <p className="info">Requested Page not found</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>

                </div>
            </div>
            {/* <!--Content Area End--> */}


        </div>
    )
}