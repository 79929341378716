import { React } from "react";

export default function CongratulationPopUp({ seeCongratulation, setSeeCongratulation, language }) {
    return (
        <div className="pay-popup-wrap" id="phonepe-pop" style={{ display: seeCongratulation ? "block" : "none" }}>
            <div className="paypopup-box">
                <div className="paypop-content">
                    <div className="logo-image">
                        <img src="images/logo.png" alt="Logo Image" />
                    </div>
                    <div className="right-image">
                        <img src="images/right.png" alt="Right Image" />
                    </div>
                    <div className="subcribe-text">
                        {/* <p className="textTitle">Congratulations!</p> */}
                        <p className="textTitle">{language?.pay_popup_payment_success_header1}</p>
                        {/* <p>Your are now a Premium User.</p> */}
                        <p>{language?.pay_popup_payment_success_header2}</p>
                        {/* <p>Enjoy ad free movies, TV Shows, Music Videos.</p> */}
                        <p>{language?.pay_popup_payment_success_header3}</p>
                    </div>
                    <div className="cpbtnsb">
                        <a href={process.env.REACT_APP_HUNGAMA_WEB_URL} className="goldbtn">
                            {/* Start Exploring */}
                            {language?.pay_popup_payment_success_button_text}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}