/* eslint-disable*/
import React, { useState, useEffect } from "react";
// import ReactGA from "react-ga";
// import slide1 from "../img/slide1.png";
import axios from "axios";
import { useLocation, useHistory } from "react-router-dom";
import get from "lodash.get";
// import { backendApiUrl } from "./utils";
import {
  ampRabbitMQBtnEvent,
  ampRabbitMQPageEvent,
  ampRabbitMQCustomEvent,
  ampRabbitMQsubBtnClickPageEvent,
  langConvrt,
} from "../../../utils";
import { language, plans } from "../../../utils/services";
import { Swiper, SwiperSlide } from "swiper/react";
// import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";

export function PlengPlan() {
  // const ref = useRef();

  let currenturl = useLocation().search;
  const couponcode = new URLSearchParams(currenturl).get("couponcode");
  const plan_id = new URLSearchParams(currenturl).get("plan_id");
  const product_id = new URLSearchParams(currenturl).get("product_id");
  const platform_id = new URLSearchParams(currenturl).get("platform_id");
  const country = new URLSearchParams(currenturl).get("country");
  const identity = new URLSearchParams(currenturl).get("identity");
  const plan_type = new URLSearchParams(currenturl).get("plan_type");
  const hardware_id = new URLSearchParams(currenturl).get("hardware_id");
  const lan = new URLSearchParams(currenturl).get("lang");
  const npay_redirect = new URLSearchParams(currenturl).get("npay_redirect");
  const npay_campaignsource = new URLSearchParams(currenturl).get(
    "npay_campaignsource"
  );
  const campaignsource = new URLSearchParams(currenturl).get("campaignsource");
  const npay_affilaite = new URLSearchParams(currenturl).get("npay_affilaite");
  const aff_id = new URLSearchParams(currenturl).get("aff_id");
  const utm_source = new URLSearchParams(currenturl).get("utm_source");
  const source = new URLSearchParams(currenturl).get("source");
  const location = useLocation().search;
  const urlparams = location.replace("?", "");
  //  const TRACKING_ID = `${process.env.REACT_APP_GA_CODE_PWA}`; // OUR_TRACKING_ID
  //  ReactGA.initialize(TRACKING_ID);
  //  ReactGA.pageview(window.location.pathname);
  //  const useAnalyticsEventTracker = (category = "Payment Init") => {
  //    const eventTracker = (action = "action init", label = "label init") => {
  //      ReactGA.event({ category, action, label });
  //    };
  //    return eventTracker;
  //  };

  const hostname = window.location.host;
  const pathname = useLocation().pathname;
  //  var ua = navigator.userAgent.toLowerCase();
  //  var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
  let history = useHistory();

  //language converter
  // const langConverter = (str) => {
  //   let newStr = "";
  //   if (lan === "" || lan === null || lan === "en") {
  //     console.log("langConverter if !");
  //     newStr = str;
  //     return newStr;
  //   } else if (lan !== "en" || lan !== "" || lan !== null) {
  //     console.log("langConverter else if !");
  //     let keyName = Object.keys(textData?.default).find(
  //       (key) => textData?.default[key] === str
  //     );
  //     newStr = textData?.secondLang[keyName];
  //     return newStr;
  //   }
  // };

  const goToPreviousPath = () => {
    if (platform_id === "1" || platform_id === "4") {
      window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/wvclose.php`;
    }
    //  history.goBack();
  };

  // console.log(currenturl);
  const [openCoupon, setOpenCoupon] = useState(false);
  const [defaultTextData, setDefaultTextData] = useState({});
  const [formData, setFormData] = useState({
    couponError: false,
    plan_id: "",
    plan_price: "",
    plan_name: "",
    plan_details_id: "",
    couponcode: "",
    currenturl: currenturl,
    couponStatus: 0,
  });

  // const [couponApplied, setCouponApplied] = useState(true);
  //  const [applyBtn, setApplyBtn] = useState(false);
  //  const [showApplyBtn, setShowApplyBtn] = useState(true);
  const [plans, setPlans] = useState({ discountPrice: " " });
  // const [sliders, setSlider] = useState([]);
  const [features, setFeatures] = useState([]);
  const [isLoadingOnce, setLoadingOnce] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [textData, setTextData] = useState({});

  const handleCoupenClick = () => {
    // amplitudeBtnEvent(identity, "Btn_Open_Coupon", product_id, platform_id);
    ampRabbitMQBtnEvent(
      {
        identity: identity,
        product_id: product_id,
        platform_id: product_id,
      },
      "Btn_Open_Coupon"
    );
    // setOpenCoupon(true);
    setOpenCoupon((perValue) => !perValue);
  };

  // console.log();

  const handleCouponCancel = (identity) => {
    // amplitudeBtnEvent(identity, "Btn_Remove_Coupon", product_id, platform_id);
    ampRabbitMQBtnEvent(
      {
        identity: identity,
        product_id: product_id,
        platform_id: platform_id,
      },
      "Btn_Remove_Coupon"
    );
    // console.log("coupon removed");
  };

  // console.log(textData);

  // Front Logger
  useEffect(() => {
    console.log("PLANS", plans);
  }, [plans]);

  useEffect(() => {
    let platforms = ["1", "4"];
    if (
      !platforms.includes(platform_id) ||
      country !== "KH" ||
      product_id !== "3"
    ) {
      history.push("/pleng/404");
    }
  }, [platform_id, product_id, country]);

  useEffect(() => {
    console.log(features);
  }, [features]);
  useEffect(() => {
    if (isLoadingOnce) {
      ampRabbitMQPageEvent({
        identity: identity,
        "Page Type": "Plan Page",
        Source: source ? source : "N/A",
        "Page Language": lan ? lan : "en",
        product_id: product_id,
        platform_id: platform_id,
        device : "mobile"
      });

      const logData = {
        url: hostname + pathname,
        params: currenturl,
        couponcode: couponcode,
        plan_id: plan_id,
        product_id: product_id,
        platform_id: platform_id,
        country: country,
        identity: identity,
        plan_type: plan_type,
        hardware_id: hardware_id,
      };

      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "api-key": process.env.REACT_APP_API_KEY,
      };

      const data = JSON.stringify(logData);
      const body = {
        logData: data,
      };

      axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/v1/frontendlogs`,
        headers: headers,
        data: body,
      })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });

      language(lan, product_id).then((res) => {
        // setTextData(res);
        //assign secondLang conditionally
        setDefaultTextData(res?.default);
        setTextData(res?.secondLang ? res["secondLang"] : res["default"]);
        // console.log(res);
      });
    }
    setLoadingOnce(false);
  }, [
    isLoading,
    hostname,
    pathname,
    currenturl,
    couponcode,
    plan_id,
    product_id,
    isLoadingOnce,
    platform_id,
    country,
    identity,
    plan_type,
    utm_source,
    source,
    aff_id,
    hardware_id,
    lan,
  ]);

  useEffect(() => {
    console.log("fomData updated:", formData);
  }, [formData]);

  const handleForm = (e, pdata, platform_id, product_id) => {
    const { name, value } = e.target;
    if (name !== "couponcode") {
      //console.log(value)
      var valarr = value.split(":");
      console.log("pln select", identity, valarr[0], valarr[2]);
      if (pdata && identity) {
        ampRabbitMQCustomEvent(
          {
            identity: identity,
            npay_currency: pdata?.plan_currency,
            npay_planid: pdata?.plan_id,
            npay_planname: pdata?.plan_name,
            npay_plan_validity:
              pdata?.duration?.duration + " " + pdata?.duration?.text,
            npay_planvalue: pdata?.plan_price,
            promo: pdata?.discountedPrice ? true : false,
            product_id: product_id,
            platform_id: platform_id,
          },
          "npay_click_planselect"
        );
        // console.log(
        //   "onSelectData:",
        //   pdata,
        //   platform_id,
        //   product_id,
        //   typeof platform_id,
        //   typeof product_id
        // );
      }
      //  gaEventTracker("pick_plan", valarr[0] + "_" + valarr[2]);

      let data = { ...formData };
      data[name] = valarr[1];
      data["plan_price"] = pdata?.discountedPrice
        ? pdata?.discountedPrice
        : valarr[2];
      data["plan_name"] = valarr[0];
      data["plan_details_id"] = pdata?.plan_details_id;
      setFormData(data);
    } else if (name === "couponcode") {
      console.log("handle Form:", name, value);
      console.log("onhandleForm plans", plans, formData?.plan_id);
      // eslint-disable-next-line eqeqeq
      let planObj = plans?.find(
        (element) => element.plan_id == formData?.plan_id
      );
      console.log("onhandleForm planObj", planObj);
      setFormData((prev) => {
        return {
          ...prev,
          couponcode: value,
          plan_id: planObj?.plan_id,
          plan_price: planObj?.discountedPrice
            ? planObj?.discountedPrice
            : planObj?.plan_price,
          plan_name: planObj?.plan_name,
          plan_details_id: planObj?.plan_details_id,
        };
      });
    }
  };

  //  const gaEventTracker = useAnalyticsEventTracker("Pleng Payment Plan");

  const handleGoogleWallet = (
    p_identity,
    p_plan_details_id,
    p_product_id,
    p_urlparams,
    p_coupon_code,
    p_payment_id
  ) => {
    // amplitudeBtnEvent(identity, "Btn_GoogleWallet", product_id, platform_id);
    ampRabbitMQBtnEvent(
      {
        identity: identity,
        product_id: product_id,
        platform_id: platform_id,
      },
      "Btn_GoogleWallet"
    );
    // gaEventTracker('google_wallet',p_plan_details_id) ;
    let url;
    url = `${process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?payment_id=${p_payment_id}&identity=${p_identity}&plan_details_id=${p_plan_details_id}&product_id=${p_product_id}&type=charge&${p_urlparams}&coupon_code=${p_coupon_code}`;
    console.log("payment url::", url);
    window.location.href = url;
  };
  const buypro = (e) => {
    // amplitudeBtnEvent(
    //   identity,
    //   "Btn_BottomFix_Buy Now",
    //   product_id,
    //   platform_id
    // );
    ampRabbitMQsubBtnClickPageEvent(
      { identity: identity, product_id: product_id, platform_id: platform_id },
      "Btn_BottomFix_Buy Now"
    );
    var plan_name_price = formData?.plan_name + "_" + formData?.plan_price;
    //  gaEventTracker("buy_pleng_pro", plan_name_price);
    if (formData.plan_id !== "") {
      if (formData.plan_id === "37" || formData.plan_id === 37) {
        var searchParams = new URLSearchParams(window.location.search);
        searchParams.set("plan_type", "event");
        currenturl = searchParams.toString();
      }
      console.log(
        "plan name,price and currenturl::",
        plan_name_price,
        currenturl
      );
      //google wallet for india
      if (platform_id === "1" && product_id === "3") {
        //google wallet
        let payment_id = "11";
        let coupon_code =
          formData?.couponStatus === 1 ? formData?.couponcode : "";
        let plan_details_id = formData?.plan_details_id;

        if (identity && plan_details_id && product_id && payment_id) {
          console.log("google wallet called!");
          handleGoogleWallet(
            identity,
            plan_details_id,
            product_id,
            urlparams,
            coupon_code,
            payment_id
          );
        }
      }
      if (platform_id === "4" && product_id === "3") {
        history.push(
          `/pleng/payment?plan_id=${formData.plan_id}&couponcode=${
            formData.couponcode === undefined ? "" : formData.couponcode
          }&${currenturl.replace("?", "")}`
        );
      }
    } else {
      alert("please select a plan");
    }
  };

  const freeCoupon = async (coupon_code) => {
    axios
      .get(
        `${process.env.REACT_APP_HANGAMA_URL}/coupons/index.php?reqfrom=app&identity=${identity}&coupon_code=${coupon_code}`
      )
      .then((res) => {
        console.log(res.data);
        const order_id = get(res, "data.order_id", "");
        if (res.data.status === 200) {
          history.push(`/payment_success?order_id=${order_id}`);
        }
        return;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClearCoupon = (e, identity) => {
    plans.map((e) => {
      return (e.discountedPrice = undefined);
    });
    setPlans((prev) => {
      return [...prev];
    });
    // eslint-disable-next-line eqeqeq
    let planObj = plans?.find(
      (element) => element.plan_id == formData?.plan_id
    );

    console.log("onClearCoupon planObj", planObj);
    setFormData((prev) => {
      return {
        ...prev,
        couponcode: "",
        couponError: false,
        couponStatus: 0,
        plan_id: planObj?.plan_id,
        plan_price: planObj?.discountedPrice
          ? planObj?.discountedPrice
          : planObj?.plan_price,
        plan_name: planObj?.plan_name,
        plan_details_id: planObj?.plan_details_id,
      };
    });
    handleCouponCancel(identity);
  };

  const verifyCoupon = (e, couponcode) => {
    if (e) e.preventDefault();
    // amplitudeBtnEvent(identity, "Btn_Submit_Coupon", product_id, platform_id);
    ampRabbitMQBtnEvent(
      {
        identity: identity,
        product_id: product_id,
        platform_id: platform_id,
      },
      "Btn_Submit_Coupon"
    );
    //  gaEventTracker(
    //    "apply_coupon",
    //    "HUNGAMA_" + {couponcode}
    //  );
    axios
      .get(
        `${process.env.REACT_APP_HANGAMA_URL}/coupons/get_discounted_value.php?coupon_code=${formData.couponcode}`
      )
      .then((res) => {
        console.log("coupon res:", res);
        console.log("sdf");
        const couponval = get(res, "data.coupon_details.value", "");
        const coupontype = get(res, "data.coupon_details.type", "");
        const couponStatus = get(res, "data.status", "");

        console.log("coupon" + couponval);
        console.log("coupontype" + coupontype);
        console.log("couponStatus" + couponStatus, typeof couponStatus);
        if (couponval !== "") {
          setOpenCoupon(false);
          if (coupontype === "free") {
            freeCoupon(formData.couponcode);
          }
          plans.map((e) => {
            let discount;
            if (coupontype === "discounted") {
              discount = e.plan_price - (couponval / 100) * e.plan_price;
            } else if (coupontype === "flat") {
              discount = e.plan_price - couponval;
            }
            return (e.discountedPrice = discount);
          });
          setPlans((prev) => {
            return [...prev];
          });
          // eslint-disable-next-line eqeqeq
          let planObj = plans?.find(
            (element) => element.plan_id == formData?.plan_id
          );

          console.log("onVerifyCoupon planObj", planObj);
          setFormData((prev) => {
            return {
              ...prev,
              couponError: false,
              couponStatus: couponStatus,
              plan_id: planObj?.plan_id,
              plan_price: planObj?.discountedPrice
                ? planObj?.discountedPrice
                : planObj?.plan_price,
              plan_name: planObj?.plan_name,
              plan_details_id: planObj?.plan_details_id,
            };
          });
        } else {
          setFormData((prev) => {
            return {
              ...prev,
              couponError: true,
              couponStatus: couponStatus,
            };
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  var bool = [];

  for (let df = 0; df < plans.length; df++) {
    const data = plans[df];
    if (df % 2) {
      continue;
    }
    let data1 = "";
    if (df + 1 !== "") {
      data1 = plans[df + 1];
    }

    bool.push({ data: data, data1: data1 });
  }

  // console.log("bool::", bool);
  // console.log("last bool::", bool[bool.length - 1]);

  useEffect(() => {
    if (isLoading) {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "api-key": process.env.REACT_APP_API_KEY,
      };

      const body = {
        product_id: product_id,
        platform_id: platform_id,
        country: country,
        identity: identity,
      };

      axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/v1/billing/plans?product_id=${body.product_id}&platform_id=${body.platform_id}&country=${body.country}&identity=${body.identity}`,
        headers: headers
        // data: body,
      })
        .then((res) => {
          const plansdata = res.data.plans;
          // const sliderInfodata = res.data.sliderInfo;
          // const featureData = res.data.features;
          // setFeatures(featureData);
          let recommendedplan_id,
            recommendedplan_id_price,
            recommendedplan_id_name,
            recommendedplan_details_id;
          plansdata.forEach((x, i) => {
            if (x.is_recommended) {
              recommendedplan_id = x.plan_id;
              recommendedplan_id_price = x.plan_price;
              recommendedplan_id_name = x.plan_name;
              recommendedplan_details_id = x.plan_details_id;
            }
          });

          setFormData((prev) => {
            return {
              ...prev,
              plan_id: recommendedplan_id,
              plan_price: recommendedplan_id_price,
              plan_name: recommendedplan_id_name,
              plan_details_id: recommendedplan_details_id,
            };
          });

          // setSlider(sliderInfodata);
          setPlans(plansdata);
          // console.log('coming')
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });

      //  var requestOptions = {
      //    method: "GET",
      //    redirect: "follow",
      //  };

      //  fetch("https://ipapi.co/json/", requestOptions)
      //    .then((response) => response.text())
      //    .then((res) => {
      //      // const result = JSON.parse(res);
      //      // // return '';
      //      // let campaignsourceval, affilaite;
      //      // if (!npay_campaignsource) { campaignsourceval = campaignsource; }
      //      // if (!npay_affilaite) { affilaite = aff_id; }
      //      /* Rabbit MQ */
      //      // const rabbitMQData = {
      //      //     'identity': identity,
      //      //     'product_id': product_id,
      //      //     'auth-key': process.env.REACT_APP_MQ_AUTH_KEY,
      //      //     'event_type': 'npay_page_plans',
      //      //     'event_time': Math.floor(new Date().getTime() / 1000),
      //      //     'queue_name': process.env.REACT_APP_QUEUE_NAME,
      //      //     'ip': result.ip,
      //      //     'country': result.country,
      //      //     'event_properties': {
      //      //         'npay_redirect': 'false',
      //      //         'campaignsource': campaignsourceval,
      //      //         'affilaite': affilaite,
      //      //     }
      //      // }
      //      // console.log(rabbitMQData);
      //      // axios({
      //      //     method: "POST",
      //      //     url: `${process.env.REACT_APP_API_URL}/v1/notify/mq/receiver`,
      //      //     headers: headers,
      //      //     data: rabbitMQData
      //      // })
      //      //     .then((res) => {
      //      //         console.log('rabbitmq')
      //      //         console.log(res);
      //      //     })
      //      //     .catch((err) => {
      //      //         console.log(err);
      //      //     });
      //    })
      //    .catch((error) => console.log("error", error));
    }
  }, [
    identity,
    npay_redirect,
    aff_id,
    campaignsource,
    npay_affilaite,
    npay_campaignsource,
    isLoading,
    product_id,
    platform_id,
    country,
  ]);

  useEffect(() => {
    if (openCoupon) {
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed"; //ios
      document.body.style.height = "100vh"; //ios
      document.body.style.width = "100%"; //ios
    } else if (!openCoupon) {
      document.body.style.overflow = "visible";
      document.body.style.removeProperty("position"); //ios
      document.body.style.removeProperty("height"); //ios
      document.body.style.removeProperty("width"); //ios
    }
  }, [openCoupon]);

  //fun
  const featureImg = (userTypeBool) => {
    if (userTypeBool) {
      return (
        <img
          alt="tick-img"
          src={
            process.env.REACT_APP_ASSETS_URL +
            "/mobile/pleng/images/tickimg.svg"
          }
        />
      );
    }
    return (
      <img
        alt="cross-img"
        src={
          process.env.REACT_APP_ASSETS_URL + "/mobile/pleng/images/crpssimg.svg"
        }
      />
    );
  };

  return (
    <>
      {isLoading ? (
        <div className="loading">Loading...</div>
      ) : (
        <>
          <div style={{ pointerEvents: openCoupon ? "none" : "auto" }}>
            {/* <div class="swiper-container swiper1">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide">
                      <div class="slidiv">
                        <div class="sliderbtmblur"></div>
                          <img alt="slider-img" src={ process.env.REACT_APP_ASSETS_URL +"/mobile/pleng/images/slider1_new_comp.png"} />
                      </div>
                    <div class="goldicnslid"></div>
                    <div class="slidftxt">Ad-free, Unlimited Downloads</div>  
                    <div class="slidstxt">Enjoy ad free music listening offline Playback and More</div>  
                  </div>
              <div class="swiper-slide">
                  <div class="slidiv">
                  <div class="sliderbtmblur"></div>
                  <img alt="slider-img" src={process.env.REACT_APP_ASSETS_URL+"/mobile/pleng/images/slider1_new_comp"} />
                  </div>
            <div class="noaddslid"></div>
            <div class="slidftxt">No Ads or Limits</div>  
            <div class="slidstxt">Subscribe today for Movies, Music, Videos, TV</div>  
            </div>
            <div class="swiper-slide">
            <div class="slidiv">
                <div class="sliderbtmblur"></div>
                <img alt="slider-img" src={process.env.REACT_APP_ASSETS_URL+"/mobile/pleng/images/slider1_new_comp"} />
            </div>
            <div class="goldicnslid"></div>
            <div class="slidftxt">Ad-free, Unlimited Downloads</div>  
            <div class="slidstxt">Enjoy ad free music listening offline Playback and More</div>  
            </div>
            <div class="swiper-slide">
            <div class="slidiv">
                <div class="sliderbtmblur"></div>
                <img  alt="slider-img" src={process.env.REACT_APP_ASSETS_URL+"/mobile/pleng/images/slider2.png"} />
            </div>
            <div class="goldicnslid"></div>
            <div class="slidftxt">No Ads or Limits</div>  
            <div class="slidstxt">Subscribe today for Movies, Music, Videos, TV</div>  
            </div>
        </div>
       
        <div class="swiper-pagination"></div>
                </div> */}
            <Swiper
              pagination={{ clickable: true }}
              className={"swiper1"}
              autoplay={{ delay: 3000 }}
            >
              {/* {sliders.length > 0 &&
                sliders
                  ?.sort(
                    (a, b) => parseFloat(a.sequence) - parseFloat(b.sequence)
                  )
                  .map((e, i) => (
                    <SwiperSlide key={i}>
                      <div className="swiper-slide">
                        <div className="slidiv">
                          <img src={e.img} alt="slider" />
                        </div>
                        {e.slider_type === "pro" ? (
                          <div className="goldicnslid"></div>
                        ) : (
                          <div className="noaddslid"></div>
                        )}
                        <div className="slidftxt">
                          {langConverter(e.slidftxt)}
                        </div>
                        <div className="slidstxt">
                          {langConverter(e.slidstxt)}
                        </div>
                      </div>
                    </SwiperSlide>
                  ))} */}
              {/* static banner code */}
              <SwiperSlide>
                <div className="swiper-slide">
                  <div className="slidiv">
                    <img
                      src={`https://assets-pay.hungama.com/assets/un/mobile/pleng/images/slider1_new_comp.png`}
                      alt="slider"
                    />
                  </div>
                  <div className="goldicnslid"></div>
                  <div className="slidftxt">{textData?.pay_banner_title_1}</div>
                  <div className="slidstxt">
                    {textData?.pay_banner_subtitle_1}
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-slide">
                  <div className="slidiv">
                    <img
                      src={`https://assets-pay.hungama.com/assets/un/mobile/pleng/images/slider1_new_comp.png`}
                      alt="slider"
                    />
                  </div>
                  <div className="noaddslid"></div>
                  <div className="slidftxt">{textData?.pay_banner_title_2}</div>
                  <div className="slidstxt">
                    {textData?.pay_banner_subtitle_2}
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
            <div className="plans">
              <div className="planmar">
                <div className="swiper-container planSwiper">
                  <div className="swiper-wrapper">
                    {/* <div class="swiper-slide">
                    <div class="bxplan">
                        <div class="paddpan">
                            <p class="typeplan">Monthly</p>
                            <div class="ruppetxt">$0.99</div>
                        </div>
                        <label class="container">
                          <input type="radio" name="radio" />
                          <span class="checkmark"></span>
                        </label>
                    </div>                    
                    <div class="bxplan">
                        <div class="paddpan">
                            <p class="typeplan">1 DAY</p>
                            <div class="ruppetxt">$0.05</div>
                        </div>
                        <label class="container">
                          <input type="radio" name="radio" />
                          <span class="checkmark"></span>
                        </label>
                    </div> 
                </div>
                <div class="swiper-slide">
                    <div class="bxplan">
                        <div class="rcomm">Recommended</div>
                        <div class="paddpan">
                            <p class="typeplan">12 MONTHS</p>
                            <div class="ruppetxt">$5.99</div>
                        </div>
                        <label class="container">
                          <input type="radio" name="radio" />
                          <span class="checkmark"></span>
                        </label>
                    </div>
                    <div class="bxplan">
                        <div class="paddpan">
                            <p class="typeplan">1 WEEK</p>
                            <div class="ruppetxt">$0.3</div>
                        </div>
                        <label class="container">
                          <input type="radio" name="radio" />
                          <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="bxplan">
                        <div class="paddpan">
                            <p class="typeplan">3 MONTHS</p>
                            <div class="ruppetxt">10K Points</div>
                        </div>
                        <label class="container">
                          <input type="radio" name="radio" />
                          <span class="checkmark"></span>
                        </label>
                    </div>
                  
                </div> */}
                    {bool?.map((el, i) => (
                      <div className="swiper-slide" key={i}>
                        <div className="bxplan">
                          {el?.data?.is_recommended && (
                            <div className="rcomm">
                              {textData?.pay_recommended_text}
                            </div>
                          )}
                          <div className="paddpan">
                            <p className="typeplan">
                              {langConvrt(
                                el?.data?.plan_name,
                                defaultTextData,
                                textData
                              )}
                            </p>
                            {el?.data?.discountedPrice ? (
                              <div className="ruppetxt">
                                <span>
                                  {el?.data?.plan_currency_symbol}
                                  {el?.data?.plan_price}
                                </span>
                                {el?.data?.plan_currency_symbol}
                                {el?.data?.discountedPrice}
                              </div>
                            ) : (
                              <div className="ruppetxt">
                                {el?.data?.plan_currency_symbol}{" "}
                                {el?.data?.plan_price}
                              </div>
                            )}
                          </div>
                          <label className="container">
                            <input
                              type="radio"
                              defaultChecked={
                                el?.data?.is_recommended && "defaultChecked"
                              }
                              value={
                                el?.data?.plan_name +
                                ":" +
                                el?.data?.plan_id +
                                ":" +
                                el?.data?.plan_price
                              }
                              name="plan_id"
                              onChange={(e) =>
                                handleForm(e, el?.data, platform_id, product_id)
                              }
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                        {el?.data1 && (
                          <div className="bxplan">
                            {el?.data1?.is_recommended && (
                              <div className="rcomm">
                                {textData?.pay_recommended_text}
                              </div>
                            )}
                            <div className="paddpan">
                              <p className="typeplan">
                                {langConvrt(
                                  el?.data1.plan_name,
                                  defaultTextData,
                                  textData
                                )}
                              </p>
                              {el?.data1?.discountedPrice ? (
                                <div className="ruppetxt">
                                  <span>
                                    {el?.data1?.plan_currency_symbol}
                                    {el?.data1?.plan_price}
                                  </span>
                                  {el?.data1?.plan_currency_symbol}
                                  {el?.data1?.discountedPrice}
                                </div>
                              ) : (
                                <div className="ruppetxt">
                                  {el?.data1?.plan_currency_symbol}{" "}
                                  {el?.data1?.plan_price}
                                </div>
                              )}
                            </div>
                            <label className="container">
                              <input
                                type="radio"
                                defaultChecked={
                                  el?.data1?.is_recommended && "defaultChecked"
                                }
                                value={
                                  el?.data1?.plan_name +
                                  ":" +
                                  el?.data1?.plan_id +
                                  ":" +
                                  el?.data1?.plan_price
                                }
                                name="plan_id"
                                onChange={(e) =>
                                  handleForm(
                                    e,
                                    el?.data1,
                                    platform_id,
                                    product_id
                                  )
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {formData?.couponStatus === 1 && formData?.couponcode !== "" ? (
                <div className="ccapplyout">
                  <span>
                    {formData?.couponcode}
                    {textData?.pay_coupon_applied_text}
                  </span>
                  <div onClick={handleClearCoupon} className="crossicn"></div>
                </div>
              ) : (
                <div className="ccod" onClick={handleCoupenClick}>
                  <div className="cupcode" data-pushbar-target="bottom">
                    {textData?.pay_coupon_placeholder_text}
                  </div>
                </div>
              )}
              <div className="CTR gemg">
                {textData?.pleng_pay_features_heading}
              </div>
              <div className="feature-bar">
                <div className="featurepadd">
                  <div className="featurehead">
                    <div className="fratcol">{textData?.pay_features}</div>
                    <div className="fratcol1">
                      <span className="adfree">
                        {textData?.pay_features_type_1}
                      </span>
                    </div>
                    <div className="fratcol2">
                      <span className="adfree">
                        {textData?.pay_features_type_2}
                      </span>
                    </div>
                  </div>
                  {/* {features?.map((data, i) => (
                    <div className="featurerow brdn" key={i}>
                      <div className="fratcolrow">{data?.featureName}</div>
                      <div className="fratcol1">
                        {featureImg(data?.userType?.free)}
                      </div>
                      <div className="fratcol2">
                        {featureImg(data?.userType?.pro)}
                      </div>
                    </div>
                  ))} */}
                  <div class="featurerow brdn">
                    <div class="fratcolrow">
                      {textData?.pleng_pay_features_list_1}
                    </div>
                    <div class="fratcol1">{featureImg(true)}</div>
                    <div class="fratcol2">{featureImg(true)}</div>
                  </div>
                  {/* <div class="featurerow brdn">
                    <div class="fratcolrow">
                      {textData?.pleng_pay_features_list_2}
                    </div>
                    <div class="fratcol1">{featureImg(true)}</div>
                    <div class="fratcol2">{featureImg(true)}</div>
                  </div> */}
                  <div class="featurerow brdn">
                    <div class="fratcolrow">
                      {textData?.pleng_pay_features_list_3}
                    </div>
                    <div class="fratcol1">{featureImg(false)}</div>
                    <div class="fratcol2">{featureImg(true)}</div>
                  </div>
                  <div class="featurerow brdn">
                    <div class="fratcolrow">
                      {textData?.pleng_pay_features_list_4}
                    </div>
                    <div class="fratcol1">{featureImg(false)}</div>
                    <div class="fratcol2">{featureImg(true)}</div>
                  </div>
                  <div class="featurerow brdn">
                    <div class="fratcolrow">
                      {textData?.pleng_pay_features_list_5}
                    </div>
                    <div class="fratcol1">{featureImg(false)}</div>
                    <div class="fratcol2">{featureImg(true)}</div>
                  </div>
                  <div class="featurerow ">
                    <div class="fratcolrow">
                      {textData?.pleng_pay_features_list_6}
                    </div>
                    <div class="fratcol1">{featureImg(true)}</div>
                    <div class="fratcol2">{featureImg(true)}</div>
                  </div>
                  {/* <div class="featurerow brdn">
                    <div class="fratcolrow">
                      {textData?.pleng_pay_features_list_7}
                    </div>
                    <div class="fratcol1">{featureImg(false)}</div>
                    <div class="fratcol2">{featureImg(true)}</div>
                  </div> */}
                  {/* <div class="featurerow">
                    <div class="fratcolrow">
                      {textData?.pleng_pay_features_list_8}
                    </div>
                    <div class="fratcol1">{featureImg(false)}</div>
                    <div class="fratcol2">{featureImg(true)}</div>
                  </div> */}
                </div>
              </div>
              <div className="trygold">
                <div className="CTR gemg">
                  {textData?.pleng_pay_pickplan_title_text}
                </div>
                <div className="takeexp">
                  {textData?.pleng_pickplan_subtitle_text}
                </div>
                <div className="CTR">
                  <a href="#planstart" className="button-blue button-pick-plan">
                    {textData?.pay_pickplan_btn_text}
                  </a>
                </div>
                {platform_id === "4" ? (
                  <>
                    <div className="itunes-terms">
                      {textData?.pleng_itunes_terms_title}
                    </div>
                    <p className="itunes-txt">
                      {textData?.pleng_itunes_terms_text}
                    </p>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="goldbgmain">
                {/* <input type="hidden" id="x_plan_name" />
        <input type="hidden" id="x_plan_price" /> */}
                <div onClick={buypro} className="button-gold d-block">
                  <span className="icon-gold"></span>
                  {textData?.pleng_pay_buyplan_btn_text}
                </div>
                <div class="CTR">
                <p class="alsub" style={{borderBottom:"0px  solid"}}>
        <span style={{color: 'white',fontSize: '10px'}}>{textData?.cancel_text}</span>
      </p>
      </div>
                {/* <div onClick={(e) => buypro()} className="goldbtn pointer">
                  <img
                    src={
                      process.env.REACT_APP_ASSETS_URL +
                      "/mobile/images/goldimg.svg"
                    }
                    alt="gold"
                  />{" "}
                  {textData?.secondLang?.pay_buyplan_btn_text
                    ? textData?.secondLang?.pay_buyplan_btn_text
                    : textData?.default?.pay_buyplan_btn_text}
                </div> */}
              </div>
            </div>
            <div className="mainwrp" id="planstart">
              <div className="header">
                {platform_id === "1" ? (
                  <div onClick={goToPreviousPath} className="arwback">
                    <span
                      onClick={goToPreviousPath}
                      className="icon-Back"
                    ></span>
                  </div>
                ) : (
                  ""
                )}
                <span className="hlogo"></span>
              </div>
            </div>
          </div>
          {/* coupon code popup */}
          <aside
            data-pushbar-id="bottom"
            className={
              openCoupon ? "pushbar from_bottom opened" : "pushbar from_bottom"
            }
          >
            <div className="paddcpbox">
              <div
                className="coupon-close"
                onClick={(e) => setOpenCoupon(false)}
                data-pushbar-close
              >
                <img
                  alt="close"
                  src={
                    process.env.REACT_APP_ASSETS_URL +
                    "/mobile/pleng/images/close-btn.svg"
                  }
                />
              </div>
              <div className="close_sep"></div>
              <div className="cptitle"> {textData?.pay_coupon_popup_text}</div>
              <div className="cpbxcon">
                <div className="cpbxcon-input-box">
                  <input
                    autoComplete="off"
                    style={{
                      borderColor: formData.couponError ? "#FA3A59" : "",
                    }}
                    value={formData.couponcode}
                    onChange={(e) => handleForm(e)}
                    name="couponcode"
                    type="text"
                  />
                  {formData.couponError && formData?.couponcode !== "" && (
                    <img
                      alt="close icon"
                      onClick={(e) => handleClearCoupon(e)}
                      src={
                        process.env.REACT_APP_ASSETS_URL +
                        "/mobile/pleng/images/coupon_error_icon.svg"
                      }
                      className="error-closebtn"
                    />
                  )}
                </div>
                <div
                  onClick={(e) => {
                    verifyCoupon(e, formData?.couponcode);
                  }}
                  className="button-blue"
                >
                  {textData?.pay_coupon_apply_text}
                </div>
              </div>
              {formData.couponError && (
                <div className="error-box">
                  {textData?.pay_coupon_error_text}
                </div>
              )}
            </div>
          </aside>
        </>
      )}
    </>
  );
}
