import React, { useState } from "react";
import ReactGA from "react-ga";
import { SUCCESS } from "../../../../constants";
import { verifyCouponCode } from "../../../utils/services";
import { Image } from "../../subcomponents/Elements";
import { amplitudeBtnEvent, ampRabbitMQBtnEvent } from "../../../utils";
// import moment from "moment";
export default function OrderSummary({
  applyCouponCode,
  paymentsData,
  updateMultipleFormData,
  isFreeTrial,
}) {
  const [showCloseBtn, setShowCloseBtn] = useState(false);
  let urlparams = new URLSearchParams(window.location.search);
  let plan_id = urlparams.get("plan_id");
  const TRACKING_ID = `${process.env.REACT_APP_GA_CODE}`; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);
  const useAnalyticsEventTracker = (category = "Payment Init") => {
    const eventTracker = (action = "action init", label = "label init") => {
      ReactGA.event({ category, action, label });
    };
    return eventTracker;
  };

  const verifyCoupon = (event) => {
    // amplitudeBtnEvent(paymentsData?.formData?.identity, "Btn_Submit_Coupon");
    ampRabbitMQBtnEvent(
      {
        identity: paymentsData?.formData?.identity,
        product_id: paymentsData?.formData?.product_id,
        platform_id: paymentsData?.formData?.platform_id,
      },
      "Btn_Submit_Coupon"
    );
    event.preventDefault();
    gaEventTracker(
      "apply",
      "coupon_nummber_" + paymentsData?.formData?.couponcode
    );

    // updateMultipleFormData({ couponValidStatus: false });
    verifyCouponCode(paymentsData?.formData?.couponcode).then((res) => {
      if (res?.status === SUCCESS) {
        applyCouponCode(res?.data);
      } else {
        // history.push("/404");
      }
    });
  };
  const gaEventTracker = useAnalyticsEventTracker("Coupon Code");

  const cancelCouponCode = (event) => {
    event.preventDefault(); // Prevent default submission
    updateMultipleFormData({ discountedPrice: "", couponcode: "" });
    setShowCloseBtn(false);
  };

  const handleCouponOnChange = (coupon) => {
    if (coupon?.length !== 0) {
      setShowCloseBtn(true);
      updateMultipleFormData({ couponcode: coupon });
    } else if (coupon?.length === 0 && coupon === "") {
      updateMultipleFormData({ couponcode: "" });
      setShowCloseBtn(false);
    }
  };
  return (
    <div className="p-contentbox-right">
      <div className="p-titlebox">
        <h2 className="p-title">
          {paymentsData?.language?.pay_order_summary_text}
        </h2>
      </div>
      <div className="order-summary-box">
        <div className="order-row flex1">
          <div className="order-product-imgbox">
            {paymentsData?.data?.planInfo?.plan_image_desktop && (
              <img
                src={paymentsData?.data?.planInfo?.plan_image_desktop}
                alt={paymentsData?.data?.planInfo?.plan_name}
                className="order-img"
              />
            )}
          </div>
          {paymentsData?.data?.planInfo?.plan_name === "VALUE PACK" ? (
            <div className="order-product-contentbox">
              <div className="op-row flex1">
                {/* <div className="oplan-name">{paymentsData.data.planInfo.plan_name}</div> */}
                <div className="oplan-name">Ads Free</div>
                {paymentsData?.formData?.discountedPrice && (
                  <div className="plan-price">
                    <span className="ruppe1">
                      {paymentsData?.data?.planInfo?.plan_currency_symbol}
                    </span>
                    {paymentsData?.data?.planInfo?.plan_price}
                  </div>
                )}
              </div>
              {/* <h3 className="opro-name">{paymentsData.data.planInfo.plan_name}</h3> */}
              <p className="opro-des">
                {paymentsData?.data?.planInfo?.plan_name}
              </p>
              <p className="opro-exprire">
                {paymentsData?.data?.planInfo?.plan_valid
                  ? "Validity 30 days"
                  : "Validity 30 days"}
              </p>
            </div>
          ) : (
            <div className="order-product-contentbox">
              <div className="op-row flex1">
                <div className="oplan-name">
                  {paymentsData?.data?.planInfo?.plan_dname}
                </div>
                {paymentsData?.formData?.discountedPrice && (
                  <div className="plan-price">
                    <span className="ruppe1">
                      {paymentsData?.data?.planInfo?.plan_currency_symbol}
                    </span>
                    {paymentsData?.data?.planInfo?.plan_price}
                  </div>
                )}
              </div>
              <h3 className="opro-name">
                {paymentsData?.data?.planInfo?.plan_name}
              </h3>
              {paymentsData?.data?.planInfo?.releasedate ? (
                <p className="opro-des">
                  {paymentsData?.data?.planInfo?.releasedate} |{" "}
                  {paymentsData?.data?.planInfo?.lang}
                </p>
              ) : (
                ""
              )}

              {paymentsData.data.planInfo.plan_dname === "live event" ? (
                <div className="event-row">
                  <span className="live-event-date">
                    {paymentsData.data.planInfo.event_date}
                  </span>
                  <span className="live-event-dot"></span>
                  <span className="live-event-time">
                    {paymentsData.data.planInfo.event_time}
                  </span>
                </div>
              ) : (
                <p className="opro-des">
                  {paymentsData.data.planInfo.plan_des}
                </p>
              )}

              <p className="opro-exprire">
                {paymentsData.data.planInfo.plan_valid}
              </p>
            </div>
          )}
        </div>
        <div className="pay-box flex1">
          <div className="pay-tittle">{paymentsData?.language?.pay_text}</div>
          <div className="pay-amount">
            <span className="ruppe1">
              {paymentsData.data.planInfo.plan_currency_symbol}
            </span>
            {paymentsData.formData.discountedPrice
              ? paymentsData.formData.discountedPrice
              : paymentsData.data.planInfo.plan_price}
          </div>
        </div>

        {/* counpon box commented on payment page */}
        {paymentsData.formData.discountedPrice ? (
          <div className="apply-coupn-code-box flex1">
            <div className="apply-coupn-wrap">
              <div className="apply-coupn-box">
                {paymentsData?.language?.pay_coupon_code_text}
                {paymentsData.formData.couponcode}
                {paymentsData?.language?.pay_coupon_applied_text}
              </div>
              <div className="apply-coupn-amount">
                <span className="ruppe1">
                  {paymentsData.data.planInfo.plan_currency_symbol}
                </span>
                {paymentsData.formData.discountedPrice
                  ? (
                    paymentsData.data.planInfo.plan_price -
                    paymentsData.formData.discountedPrice
                  )?.toFixed()
                  : 0}
                {paymentsData?.language?.pay_coupon_off_text}
              </div>
            </div>
            <div className="coupn-close-box">
              <span
                className="coupn-close-btn"
                onClick={(e) => cancelCouponCode(e)}
              >
                <Image src="coupn-close.svg" alt="coupon close" />
              </span>
            </div>
          </div>
        ) : (
          <div className="coupn-code-box flex1">
            <div
              className={`coupn-box ${paymentsData.formData?.couponValidStatus === false &&
                  paymentsData.formData.couponcode !== ""
                  ? "coupon-error-border"
                  : ""
                }`}
            >
              <input
                type="text"
                value={paymentsData?.formData?.couponcode}
                placeholder={paymentsData?.language?.pay_coupon_placeholder}
                className="pay-input"
                onChange={(e) => handleCouponOnChange(e.target.value)}
              />
              {showCloseBtn === true && (
                <img
                  alt="close icon"
                  src="https://assets-pay.hungama.com/assets/un/web/images/coupon-error-close.svg"
                  class="cb-error-closebtn pointer"
                  onClick={() => {
                    updateMultipleFormData({
                      couponcode: "",
                      couponValidStatus: true,
                    });
                    setShowCloseBtn(false);
                  }}
                />
              )}

              {paymentsData.formData.couponValidStatus === false &&
                paymentsData.formData?.couponcode !== "" &&
                paymentsData.formData?.isCouponExpired === false &&
                paymentsData.formData?.CouponNotApplicableForPlan === false && (
                  <p class="coupon-error-msg">
                    {paymentsData?.language?.pay_coupon_invalid_text}
                  </p>
                )}
              {paymentsData.formData.couponValidStatus === false &&
                paymentsData.formData?.couponcode !== "" &&
                paymentsData.formData?.isCouponExpired === false &&
                paymentsData.formData?.CouponNotApplicableForPlan === true && (
                  <p class="coupon-error-msg ">
                    {paymentsData?.language?.pay_coupon_not_applicable_for_plan}
                  </p>
                )}
              {paymentsData.formData.couponValidStatus === false &&
                paymentsData.formData?.isCouponExpired === true &&
                paymentsData.formData?.couponcode !== "" && (
                  <p class="coupon-error-msg">
                    {paymentsData?.language?.pay_coupon_expired_text}
                  </p>
                )}
            </div>
            <div className="coupn-btnbox">
              <input
                type="button"
                disabled={
                  isFreeTrial?.status === false &&
                    isFreeTrial?.plan_id === plan_id
                    ? true
                    : false
                }
                onClick={(e) => verifyCoupon(e)}
                value={paymentsData?.language?.pay_coupon_apply_text}
                className="payment-btn"
              />
            </div>
          </div>
        )}
        <div className="final-payment-box">
          {/* counpon box commented on payment page */}
          <div className="discount-box flex1">
            <div className="discount-text">
              {paymentsData?.language?.discount_text} (-)
            </div>
            <div className="discount-amount">
              <span className="ruppe1">
                {paymentsData.data.planInfo.plan_currency_symbol}
              </span>
              {paymentsData.formData.discountedPrice
                ? (
                  paymentsData.data.planInfo.plan_price -
                  paymentsData.formData.discountedPrice
                )?.toFixed(2)
                : 0}
            </div>
          </div>
          <div className="total-amount-box flex1">
            <div className="total-amount-text">
              {paymentsData?.language?.total_text}
            </div>
            <div className="total-amount">
              <span className="ruppe1">
                {paymentsData.data.planInfo.plan_currency_symbol}
              </span>
              {paymentsData.formData.discountedPrice
                ? paymentsData.formData.discountedPrice
                : paymentsData.data.planInfo.plan_price}
            </div>
          </div>
        </div>
      </div>
      <div className="agreement-box">
        {paymentsData?.language?.pay_order_sum_agreements_text1}
        <a
          rel="noreferrer"
          href={`${process.env.REACT_APP_HUNGAMA_WEB_URL}/privacy-policy`}
          target="_blank"
        >
          {paymentsData?.language?.pay_order_sum_agreements_text2}
        </a>
        {paymentsData?.language?.pay_order_sum_agreements_text3}
        <a
          rel="noreferrer"
          href={`${process.env.REACT_APP_HUNGAMA_WEB_URL}/conditions`}
          target="_blank"
        >
          {paymentsData?.language?.pay_order_sum_agreements_text4}
        </a>
        {paymentsData?.language?.pay_order_sum_agreements_text5}
      </div>
    </div>
  );
}
