import React from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";
export default function Tabs({
  paymentsData,
  tabToShow,
  isFreeTrial,
  plan_id,
}) {
  const TRACKING_ID = `${process.env.REACT_APP_GA_CODE}`; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);
  const useAnalyticsEventTracker = (category = "Payment Init") => {
    const eventTracker = (action = "action init", label = "label init") => {
      ReactGA.event({ category, action, label });
    };
    return eventTracker;
  };
  const gaEventTracker = useAnalyticsEventTracker("Payment Gateway");
  gaEventTracker(
    paymentsData?.formData?.tabToShow,
    paymentsData?.data?.planInfo?.plan_name
  );
  const location = useLocation().search;
  const user_upgradable = new URLSearchParams(location).get("upgradable");

  var showCoinsTab = false;
  var plan_price_for_enable_coins = false;
  var amt_to_enable_coins = 0;
  var current_user_coins = paymentsData?.data?.total_user_coins;
  if (paymentsData?.data?.planInfo?.plan_price) {
    amt_to_enable_coins = paymentsData?.data?.planInfo?.plan_price * 40;
    if (current_user_coins >= amt_to_enable_coins) {
      plan_price_for_enable_coins = true;
    }
  }
  if (
    /Rent/.test(paymentsData?.data?.planInfo?.plan_dname) ||
    /event/.test(paymentsData?.data?.planInfo?.plan_dname)
  ) {
    showCoinsTab = false;
  } else {
    if (plan_price_for_enable_coins) {
      showCoinsTab = true;
    }
    if (user_upgradable === "1" && showCoinsTab) {
      showCoinsTab = false;
    } else if (user_upgradable === "1" && showCoinsTab === false) {
      showCoinsTab = false;
    } else if (user_upgradable === "0" && showCoinsTab === true) {
      showCoinsTab = true;
    }
  }

  /* Params */

  return (
    <>
      {/* {paymentsData.data.featuredPayment.payment_id && (
                <div className={paymentsData.formData.tabToShow === "preferred" ? "upitab active" : "upitab"} onClick={() => tabToShow('preferred')} >
                    <div className="tab-iconbox tab-preferred-icon"></div>
                    <div className="tab-namebox">Preferred Mode<span>Exciting Offers</span></div>
                </div>
            )} */}

      {paymentsData?.data?.payments.map((data, i) => (
        <div key={i}>
          {data?.plan_group_type === "upiPayments" && (
            <div
              className={
                paymentsData?.formData?.tabToShow === "upi"
                  ? "upitab active"
                  : "upitab"
              }
              onClick={() => tabToShow("upi")}
            >
              <div className="tab-iconbox tab-upi-icon"></div>
              <div className="tab-namebox">
                {/* UPI Payment<span>Pay Directly from your Bank account</span> */}
                {paymentsData?.language?.pay_tab_upi_title}
                <span>{paymentsData?.language?.pay_tab_upi_subtitle}</span>
              </div>
            </div>
          )}

          {data?.plan_group_type === "CC" && (
            <div
              key={i}
              className={
                paymentsData?.formData?.tabToShow === "credit"
                  ? "upitab active"
                  : "upitab"
              }
              onClick={() => tabToShow("credit")}
            >
              <div className="tab-iconbox tab-credit-icon"></div>
              <div className="tab-namebox">
                {isFreeTrial?.status === false &&
                  isFreeTrial?.plan_id === plan_id
                  ? paymentsData?.language?.pay_cc_dc_trialoffer_label
                  : paymentsData?.language?.pay_cc_dc_label}
                <span>{paymentsData?.language?.pay_cc_dc_label_subtitle}</span>
              </div>
            </div>
          )}

          {data?.plan_group_type === "netBankingPayments" && (
            <div
              className={
                paymentsData?.formData?.tabToShow === "netbanking"
                  ? "upitab active"
                  : "upitab"
              }
              onClick={() => tabToShow("netbanking")}
            >
              <div className="tab-iconbox tab-net-banking-icon"></div>
              <div className="tab-namebox">
                {paymentsData?.language?.pay_netbanking_label}
                <span>
                  {paymentsData?.language?.pay_netbanking_label_subtitle}
                </span>
              </div>
            </div>
          )}

          {data?.plan_group_type === "walletPayments" && (
            <div
              className={
                paymentsData?.formData?.tabToShow === "wallet"
                  ? "upitab active"
                  : "upitab"
              }
              onClick={() => tabToShow("wallet")}
            >
              <div className="tab-iconbox tab-wallet-icon"></div>
              <div className="tab-namebox">
                {paymentsData?.language?.pay_wallets_and_more_title}
                <span>
                  {paymentsData?.language?.pay_wallets_and_more_title_subtitle}
                </span>
              </div>
            </div>
          )}
          {/* coin payment made hidden on 17-03-23 */}
          {/* {showCoinsTab && data?.plan_group_type === "COINS" && (
            <div
              key={i}
              className={
                paymentsData?.formData?.tabToShow === "coins"
                  ? "upitab active"
                  : "upitab"
              }
              onClick={() => tabToShow("coins")}
            >
              <div className="tab-iconbox tab-coins-icon"></div>
              <div className="tab-namebox">
                {paymentsData?.language?.pay_hungama_coins_label}
                <span>{paymentsData?.language?.pay_reedem_hungama_coins}</span>
              </div>
            </div>
          )} */}
        </div>
      ))}
    </>
  );
}
