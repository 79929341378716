import { React } from "react";
import { Image } from "../../subcomponents/Elements";
import { langConvrt } from "../../../utils";

export default function SeeBenefitsPopUp({ seeBenefit, setSeeBenefit, selectedPlan, setSeeSubcribe, plansData }) {
    return (
        <div className="pay-popup-wrap" id="phonepe-pop" style={{ display: seeBenefit ? "block" : "none" }}>
            <div className="paypopup-box pop-up-box">
                <div className="paypop-content">
                    <div className="pop-pay-icon-box pop-heading">
                        <p className="pop-secure price-plan">{selectedPlan?.plan_currency_symbol}{selectedPlan?.plan_price}</p>
                        <p className="pop-secure day-plan">{langConvrt(selectedPlan?.plan_name, plansData?.defaultlang, plansData?.language)} {plansData?.language?.plan_benefits_header}</p>
                    </div>
                    <div className="pop-plan-benefits">
                        <img src="images/ads.png" alt="Ads Image" />
                        <img src="images/streaming.png" alt="Streaming Image" />
                        <img
                            src={`images/data${selectedPlan?.plan_name === 'DAILY'
                                ? ''
                                : selectedPlan?.plan_name === 'WEEKLY'
                                    ? '3gb'
                                    : selectedPlan?.plan_name === 'MONTHLY MS'
                                        ? '5gb'
                                        : selectedPlan?.plan_name === 'MONTHLY ML'
                                            ? '10gb'
                                            : ''
                                }.png`}
                            alt="Data Image"
                        />
                        <img src="images/access.png" alt="Access Image" />
                    </div>
                    <div className="cpbtnsb">
                        <a
                            onClick={() => {
                                setSeeSubcribe(true)
                                setSeeBenefit(false)
                            }}
                            className="goldbtn"
                        >
                            <Image src="goldimg.svg" alt="golimg" />
                            {/* Subscribe */}
                            {plansData?.language?.pay_subscribe_button_txt}
                        </a>
                    </div>
                    {/* <p className="pop-cancel" onClick={() => setSeeBenefit(false)}>Cancel</p> */}
                    <p className="pop-cancel" onClick={() => setSeeBenefit(false)}>{plansData?.language?.pay_popup_cancel}</p>
                </div>
            </div>
        </div>
    );
}
